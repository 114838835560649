<h1>Lamellen</h1>

<div *ngIf="step==0">
  <div *ngFor="let item of lamellen">
    <div>
      <div>{{item.locatienaam}} [ {{item.aantalx}} x {{item.aantaly}} ]</div>
      <div>{{item.replacedcount}}/{{item.totaallamellen}}</div> Moeten Vervangen Worden
    </div>
  </div>
</div>

<div class="button newinspection" (click)="BeginInspectie()">Nieuwe Inspectie</div>

<!-- Kies Locatie -->
<div id="step1" *ngIf="step==1">
  <h1>Stap 1</h1>
  <h2>Kies je locatie</h2>
  <div *ngFor="let item of locations">
    <div *ngIf="item.zichtbaar" class="item">
      <div class="row">
        <div class="col naam" (click)="Step2(item)">{{item.Naam}} [ {{item.aantalx}} x {{item.aantaly}} ]</div>
      </div>
    </div>
  </div>
</div>

<!-- Kies Matrix -->
<div id="step2"  *ngIf="step==2">
  <h1>Step 2</h1>
  <h2>Doe de meting van de lamellen</h2>
  <div>
    {{id}}<br>
    {{locatie}}<br>
    {{kostendrager}}<br>
    {{locatienummer}}<br>
    [{{i}} x {{j}}]<br>
    Inspectie begonnen op {{begintijd}}<br>
  </div>
  <div>
    <ul class="table">
      <li>
        <a></a>
        <a *ngFor="let item of a; let ind = index">{{alphabet[ind]}}</a>
      </li>
      <li *ngFor="let item of b">
        <a>{{item+1}}</a>
        <a *ngFor="let items of a">
        {{items}}/{{item+1}} <input type="text" [(ngModel)]=lammel[((item+1).toString())+items]/>
        </a>
      </li>
    </ul>
  </div>
  <div>
    <button (click)=Step3()>Opslaan</button>
  </div>
</div>

<!-- Verstuur Matrix -->
<div id="step3" *ngIf="step==3">
  <h1>Step 3</h1>
  <div>
    <button (click)="Step4()">See List</button>
  </div>
</div>
