<div class="container">
  <div class="logo">
    <img src="/assets/roos.svg"/>
  </div>
  <div class="header">
    <h1>Log In</h1>
  </div>
  <div class="section">
    <form [formGroup]="loginForm">
      <div class="label">
        <label>Email address</label>
      </div>
      <div class="input">
        <input type="email" formControlName="email" class="form-control">
      </div>
      <div class="label">
        <label>Password</label>
      </div>
      <div class="input">
        <input type="password" class="form-control" formControlName="password">
      </div>
      <div class="error">
        <label class="error">{{errorMessage}}</label>
      </div>
      <div class="button">
        <button type="submit" (click)="tryLogin(loginForm.value)">Login</button>
      </div>
    </form>
  </div>
</div>
