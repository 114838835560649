import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { UserComponent }        from './user/user.component';
import { UserResolver }         from './user/user.resolver';
import { AuthGuard }            from './service/auth.guard';


import {NoAuth}                 from './noauth/noauth.component';
import {PageCookies }          from './noauth/cookies.component';

import {OAuth}           from './oauth/oauth.component';
import {OAuthLogin}      from './oauth/oauth-login.component';
import {OAuthRegister}   from './oauth/oauth-register.component';
import {OAuthChange}     from './oauth/oauth-change.component';

import {Auth}             from './auth/auth.component';
import {AuthHome}         from './auth/auth-home.component';
import {AuthLocations}    from './auth/auth-locations.component';
import {AuthStaff}        from './auth/auth-staff.component';
import {AuthLamellen}     from './auth/auth-lamellen.component';

import {Page404}        from './404/404.component'

const routes: Routes = [
  { path: '', redirectTo: 'oauth/login', pathMatch: 'full'},
  {path: 'legal', component: NoAuth, children:[
    {path: 'cookies', component: PageCookies}
  ]},
  { path: 'oauth', component:OAuth, children:[
    { path: 'login', component: OAuthLogin, canActivate: [AuthGuard] },
    { path: 'register', component: OAuthRegister, canActivate: [AuthGuard] },
    { path: 'change', component: OAuthChange },
  ]},

  { path: 'user', component: UserComponent,  resolve: { data: UserResolver}},

  { path: 'auth', component: Auth,  resolve: { data: UserResolver}, children:[
    { path: 'home', component: AuthHome },
    { path: 'locations', component: AuthLocations },
    { path: 'staff', component: AuthStaff },
    { path: 'lamellen', component: AuthLamellen }
  ]},

  { path: '404', component: Page404},
  { path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
