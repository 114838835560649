import { Component, OnInit } from '@angular/core';
import { UserService } from '../service/user.service';
import { AuthService } from '../service/auth.service';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FirebaseUserModel } from '../service/user.model';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'page-user',
  templateUrl: 'user.component.html',
  styleUrls: ['user.component.scss']
})
export class UserComponent implements OnInit{

  user: FirebaseUserModel = new FirebaseUserModel();
  profileForm: FormGroup;

  constructor(
    public userService: UserService,
    public authService: AuthService,
    public route: ActivatedRoute,
    private location : Location,
    private fb: FormBuilder,
    public router: Router,
  ) {
  }


  ngOnInit(): void {
    this.route.data.subscribe(routeData => {
      let data = routeData['data'];
      if (data) {
        this.user = data;
        this.createForm(this.user.name);
      }
    })
  }



  createForm(name) {
    this.profileForm = this.fb.group({
      name: [name, Validators.required ]
    });
  }


  save(value){
    this.userService.updateCurrentUser(value)
    .then(res => {
      console.log(res);
    }, err => console.log(err))
  }


  logout(){
    this.authService.doLogout()
    .then((res) => {
      this.router.navigateByUrl('/oauth/login');
    }, (error) => {
      console.log("Logout error", error);
    });
  }
}
