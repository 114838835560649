import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'PageCookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss']
})
export class PageCookies {

}
