import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgpSortModule } from "ngp-sort-pipe";


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';


import { environment } from '../environments/environment';
import { auth } from 'firebase/app';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { CookieService } from 'ngx-cookie-service';

import { AgmCoreModule } from '@agm/core';


import {OAuth}            from './oauth/oauth.component';
import {OAuthLogin}       from './oauth/oauth-login.component';
import {OAuthRegister}    from './oauth/oauth-register.component';
import {OAuthChange}      from './oauth/oauth-change.component';

import {Auth}                 from './auth/auth.component';
import {AuthHome}             from './auth/auth-home.component';
import {AuthLocations}        from './auth/auth-locations.component';
import {AuthStaff}            from './auth/auth-staff.component';
import {AuthLamellen}         from './auth/auth-lamellen.component';

import { AuthGuard }      from './service/auth.guard';
import { AuthService }    from './service/auth.service';
import { UserService }    from './service/user.service';
import { CrudService }    from './service/crud.service';

import { UserComponent }  from './user/user.component';
import { UserResolver }   from './user/user.resolver';


import {NoAuth}           from './noauth/noauth.component';
import {PageCookies}      from './noauth/cookies.component';

import {Cookies}          from './components/cookies.component';

import {Page404}          from './404/404.component';



import { MatSliderModule }  from '@angular/material/slider';
import { MatRadioModule }   from '@angular/material/radio';

@NgModule({
  declarations: [
    AppComponent,

    OAuth,
    OAuthLogin,
    OAuthChange,
    OAuthRegister,

    Auth,
    AuthHome,
    AuthLocations,
    AuthStaff,
    AuthLamellen,

    NoAuth,
    PageCookies,

    UserComponent,

    Cookies,

    Page404,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,

    BrowserAnimationsModule,

    FormsModule,
    ReactiveFormsModule,

    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,

    NgpSortModule,

    MatSliderModule,
    MatRadioModule,

    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCBT122QKkiH8wumdxvq-hCxJvvhneROs4',
      libraries: ['places']
    })
  ],
  providers: [AuthService, UserService, UserResolver, AuthGuard, CookieService, CrudService],
  bootstrap: [AppComponent]
})
export class AppModule { }
