import { Injectable } from '@angular/core';

import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class CrudService {

  constructor(
    private firestore: AngularFirestore
  ) { }


  /*Locations*/
  create_NewLocations(record) {
    return this.firestore.collection('DB/Locations/lamellen').add(record);
  }
  read_Locations() {
    return this.firestore.collection('DB/Locations/lamellen').snapshotChanges();
  }
  update_Locations(record_id,record){
    this.firestore.doc('DB/Locations/lamellen/' + record_id).update(record);
  }
  delete_Locations(record_id) {
    this.firestore.doc('DB/Locations/lamellen/' + record_id).delete();
  }
  /*Locations*/
  create_NewLamellen(record) {
    return this.firestore.collection('DB/Inspecties/lamellen').add(record);
  }
  read_Lamellen() {
    return this.firestore.collection('DB/Inspecties/lamellen').snapshotChanges();
  }
  update_Lamellen(record_id,record){
    this.firestore.doc('DB/Inspecties/lamellen/' + record_id).update(record);
  }
  delete_Lamellen(record_id) {
    this.firestore.doc('DB/Inspecties/lamellen/' + record_id).delete();
  }


}
