
<nav>
  <img src="./assets/roos.svg" routerLink="/auth/home"/>
  <div class="item" routerLinkActive="active" routerLink="/auth/home"><a>Home</a></div>
  <div class="item" routerLinkActive="active" routerLink="/auth/lamellen"><a>Lamellen Inspectie</a></div>
  <!--<div class="item" routerLinkActive="active" routerLink="/auth/rapport"><a>Service Rapport</a></div>-->
  <div class="item" routerLinkActive="active" routerLink="/auth/locations"><a>Locaties</a></div>
  <div class="item" routerLinkActive="active" routerLink="/user"><a>Log Out</a></div>
</nav>
<div id="status-container">
  <div class="noerror"><a>Current System Status Is: HEALTHY</a></div>
</div>
<div id="auth-container">
  <router-outlet></router-outlet>
</div>




<div id="footer-container">
  <div>
    <ul>
      <li>
        Language
        <ul>
          <li>English</li>
          <li>Dutch</li>
          <li>German</li>
        </ul>
      </li>
      <li>
        Locations
        <ul>
          <li>Joure</li>
          <li>Lemmer</li>
        </ul>
      </li>
    </ul>

  </div>

  <img src="./assets/AUTRON.svg"/>
</div>
