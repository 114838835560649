<h2>Staff</h2>

<div>

      <a>Lijst van gebruikers
      Naam
      ID
      Intern ID
      Apparaat
      Heeft Telefoon -Type + Bla Bla
      Heeft Laptop -Type + IDHeeft Auto Nummerborden
    </a>
</div>

<div>
  Nieuwe gebruiker toevoegen

</div>
