<div id="bodyx">
  <div class="spanner">
    <div class="container">
      <div class="logo">
        <img src="/assets/roos.svg"/>
      </div>
      <div class="header">
        <h1>User Info</h1>
      </div>

      <div>
        <a>Logged in as: {{user.uid}}</a>
      </div>
      <div>
        <a routerLink="/auth">Continue to portal</a>
      </div>
      <div class="button">
        <button (click)="logout()">Logout</button>
      </div>

    </div>


  </div>
</div>
