import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';


import { CrudService } from '../service/crud.service';


@Component({
  selector: 'auth-lamellen',
  templateUrl: './auth-lamellen.component.html',
  styleUrls: ['./auth-lamellen.component.scss']
})
export class AuthLamellen{
  items:any;
  i:number;
  j:number;
  n:number;
  step:number;
  id:string;
  locatie:string;
  locations:any;
  lamellen:any;
  kostendrager:string;
  locatienummer:string;
  aantalx:number;
  aantaly:number;
  a:any;
  b:any;
  alphabet:any = {};
  c:number;
  begintijd: Date;
  lammel:any = {};
  lammelwrong:any = {};
  replaced:any = {};

  constructor(private crudService: CrudService) {
  }

  ngOnInit() {
    this.step=0;
    this.crudService.read_Locations().subscribe(data => {
      this.locations = data.map(e => {
        return {
          id: e.payload.doc.id,
          isEdit: false,
          Naam: e.payload.doc.data()['Naam'],
          LocatieNummer: e.payload.doc.data()['LocatieNummer'],
          Omschrijving: e.payload.doc.data()['Omschrijving'],
          Kostendrager: e.payload.doc.data()['Kostendrager'],
          zichtbaar: e.payload.doc.data()['zichtbaar'],
          aantalx: e.payload.doc.data()['x'],
          aantaly: e.payload.doc.data()['y'],
        };
      });
    });
    this.crudService.read_Lamellen().subscribe(data => {
      this.lamellen = data.map(e => {
        return {
          id: e.payload.doc.id,
          isEdit: false,
          locateid: e.payload.doc.data()['locatieid'],
          locatiekostendrager: e.payload.doc.data()['Locatiekostendrager'],
          locatienaam: e.payload.doc.data()['locatienaam'],
          locatienummer: e.payload.doc.data()['locatienummer'],
          totaallamellen: e.payload.doc.data()['totaal-lamellen'],
          aantalx: e.payload.doc.data()['x'],
          aantaly: e.payload.doc.data()['y'],
          inspectie: e.payload.doc.data()['inspectie'],
          replacedcount: e.payload.doc.data()['replacedcount'],
          replaced:e.payload.doc.data()['replaced'],
        };
      });
    });
  }

  BeginInspectie(){
    this.step=1;
    this.begintijd=new Date();

  }
  Step2(record) {
    this.a=[];
    this.b=[];
    this.step=2;
    this.id=record.id;
    this.locatie=record.Naam;
    this.kostendrager=record.Kostendrager;
    this.locatienummer=record.LocatieNummer;
    this.i=record.aantalx;
    this.j=record.aantaly;
    this.aantalx=record.aantalx;
    this.aantaly=record.aantaly;
    this.c=this.i;
    this.alphabet=['a','b','c','d','e','f','g','h','i']
    for (let n = 0; n < this.i; n++) {
      this.a.push(this.alphabet[n]);
    }
    for (let n = 0; n < this.j; n++) {
      this.b.push(n);
    }
  }

  Step3(){
    let record = {};
    record['locatienaam'] = this.locatie;
    record['locatiekostendrager'] = this.kostendrager;
    record['locatienummer'] = this.locatienummer;
    record['locatieid'] = this.id;
    record['zichtbaar'] = 1;
    record['x']=this.i;
    record['y']=this.j;
    record['totaal-lamellen'] = this.aantalx * this.aantaly;
    record['inspectie']=this.lammel;
    let result: any = [];
    Object.keys(this.lammel).map((key) =>
       result.push( +this.lammel[key] ),
    );
    this.replaced=result.filter(n => n>=7);
    record['replaced']=this.replaced;
    record['replacedcount']=this.replaced.length;

    this.crudService.create_NewLamellen(record).then(resp => {
      this.locatie = "";
      this.kostendrager = "";
      this.locatienummer = "";
      this.id = "";
      this.aantalx = undefined;
      this.aantaly = undefined;
    })
      .catch(error => {
        console.log(error);
      });
    this.step=3;
  }
  Step4(){
    this.step=0;
  }

}
