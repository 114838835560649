import { Component } from '@angular/core';

@Component({
  selector: 'oauth-change',
  templateUrl: './oauth-change.component.html',
  styleUrls: ['./oauth-change.component.scss']
})
export class OAuthChange{
  title = 'Change';
}
