import { Component, OnInit  } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'noauth',
  templateUrl: './noauth.component.html',
  styleUrls: ['./noauth.component.scss']
})
export class NoAuth {
  public cookieValue: string;

  constructor(private cookieService : CookieService) {
    this.cookieValue = this.cookieService.get('fsdata-accept');
    console.log(this.cookieValue);
  }

  public ngOninit(): void{
  }
}
