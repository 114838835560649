import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';

import { CrudService } from '../service/crud.service';

@Component({
  selector: 'auth-locations',
  templateUrl: './auth-locations.component.html',
  styleUrls: ['./auth-locations.component.scss']
})
export class AuthLocations{
  locations:any;
  locationNaam: string;
  locationOmschrijving: string;
  locationKostendrager: string;
  locationLocatieNummer: string;
  locationZichtbaar: number;
  aantalx:number;
  aantaly:number;

  constructor(private crudService: CrudService) {
    this.locationNaam = "";
    this.locationOmschrijving = "";
    this.locationKostendrager = "";
    this.locationLocatieNummer = "";
    this.locationZichtbaar = 1;
    this.aantalx = undefined;
    this.aantaly = undefined;
  }

  ngOnInit() {
    this.crudService.read_Locations().subscribe(data => {
      this.locations = data.map(e => {
        return {
          id: e.payload.doc.id,
          isEdit: false,
          Naam: e.payload.doc.data()['Naam'],
          LocatieNummer: e.payload.doc.data()['LocatieNummer'],
          Omschrijving: e.payload.doc.data()['Omschrijving'],
          Kostendrager: e.payload.doc.data()['Kostendrager'],
          zichtbaar: e.payload.doc.data()['zichtbaar'],
          aantalx: e.payload.doc.data()['x'],
          aantaly: e.payload.doc.data()['y']
        };
      });
    });
  }


  CreateRecord() {
    let record = {};
    record['Naam'] = this.locationNaam;
    record['LocatieNummer'] = this.locationLocatieNummer;
    record['Omschrijving'] = this.locationOmschrijving;
    record['Kostendrager'] = this.locationKostendrager;
    record['zichtbaar'] = 1;
    record['x']=this.aantalx;
    record['y']=this.aantaly;
    this.crudService.create_NewLocations(record).then(resp => {
      this.locationNaam = "";
      this.locationOmschrijving = "";
      this.locationLocatieNummer = "";
      this.locationKostendrager = "";
      this.aantalx = undefined;
      this.aantaly = undefined;
    })
      .catch(error => {
        console.log(error);
      });
  }
  EditRecord(record) {
    record.isEdit = true;
    record.editLocationNaam = record.Naam;
    record.editLocationOmschrijving = record.Omschrijving;
    record.editLocationLocatieNummer = record.LocatieNummer;
    record.editLocationKostendrager = record.Kostendrager;
    record.editAantalx = record.aantalx;
    record.editAantaly = record.aantaly;
  }
  RemoveRecord(rowID) {
    this.crudService.delete_Locations(rowID);
  }
  UpdateRecord(recordRow) {
    let record = {};
    record['Naam'] = recordRow.editLocationNaam;
    record['LocatieNummer'] = recordRow.editLocationLocatieNummer;
    record['Omschrijving'] = recordRow.editLocationOmschrijving;
    record['Kostendrager'] = recordRow.editLocationKostendrager;
    record['zichtbaar'] = 1;
    record['x']=recordRow.editAantalx;
    record['y']=recordRow.editAantaly;
    this.crudService.update_Locations(recordRow.id, record);
    recordRow.isEdit = false;
  }


}
