import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';

@Component({
  selector: 'auth-staff',
  templateUrl: './auth-staff.component.html',
  styleUrls: ['./auth-staff.component.scss']
})
export class AuthStaff{
}
