<div class="spanner">
  <div id="add">
    <div class="bg">
      <h2>Locatie Toevoegen</h2>
      <div class=inputitem><input type="text" class="form-control" [(ngModel)]="locationNaam" placeholder="Naam"></div>
      <div class=inputitem><input type="text" class="form-control" [(ngModel)]="locationLocatieNummer" placeholder="Locatie Nummer"></div>
      <div class=inputitem><input type="text" class="form-control" [(ngModel)]="locationOmschrijving" placeholder="Omschrijving"></div>
      <div class=inputitem><input type="text" class="form-control" [(ngModel)]="locationKostendrager" placeholder="Kostendrager"></div>
      <div class=inputitem><input type="text" class="form-control" [(ngModel)]="aantalx" placeholder="Aantal Lamellen X"></div>
      <div class=inputitem><input type="text" class="form-control" [(ngModel)]="aantaly" placeholder="Aantal Lamellen Y"></div>
      <button type="button" (click)="CreateRecord()" [disabled]="!locationNaam" class="btn btn-primary">+
        Add Location
      </button>
    </div>
  </div>
</div>


<div id="list">
<div class="spanner">
  <div class="row">
    <div class="col naam">NAAM</div>
    <div class="col locatienum">LOCATIE NUMMER</div>
    <div class="col omschrijving">OMSCHRIJVING</div>
    <div class="col kostendrager">KOSTENDRAGER</div>
    <div class="col zichtbaar">ZICHTBAAR</div>
    <div class="col lamellen">Aantal Horizontaal</div>
    <div class="col lamellen">Aantal Verticaal</div>
    <div class="col edit"></div>
    <div class="col delete"></div>
  </div>
  <div *ngFor="let item of locations">
    <div *ngIf="!item.isEdit" class="item">
      <div class="row">
        <div class="col naam">{{item.Naam}}</div>
        <div class="col locatienum">{{item.LocatieNummer}}</div>
        <div class="col omschrijving">{{item.Omschrijving}}</div>
        <div class="col kostendrager">{{item.Kostendrager}}</div>
        <div class="col zichtbaar">Zichtbaar: {{item.zichtbaar}}</div>
        <div class="col lamellen">{{item.aantalx}}</div>
        <div class="col lamellen">{{item.aantaly}}</div>
        <div class="col button"><a (click)="EditRecord(item)">Edit</a></div>
        <div class="col button delete"><a (click)="RemoveRecord(item.id)">Delete</a></div>
      </div>
    </div>
    <div *ngIf="item.isEdit" class="edititem">
      <div class="row">
        <div class="col naam"><input type="text" class="form-control" [(ngModel)]="item.editLocationNaam" placeholder="Naam"></div>
        <div class="col locatienum"><input type="text" class="form-control" [(ngModel)]="item.editLocationLocatieNummer" placeholder="LocatieNummer"></div>
        <div class="col omschrijving"><input type="text" class="form-control" [(ngModel)]="item.editLocationOmschrijving" placeholder="Omschrijving"></div>
        <div class="col kostendrager"><input type="text" class="form-control" [(ngModel)]="item.editLocationKostendrager" placeholder="Kostendrager"></div>
        <div class="col zichtbaar">Zichtbaar:{{item.zichtbaar}}</div>
        <div class="col lamellen"><input type="text" class="form-control" [(ngModel)]="item.editAantalx" placeholder="Lamellen Horizontaal"></div>
        <div class="col lamellen"><input type="text" class="form-control" [(ngModel)]="item.editAantaly" placeholder="Lamellen Verticaal"></div>
        <div class="col button"><a (click)="item.isEdit = false">Cancel</a></div>
        <div class="col button update"><a (click)="UpdateRecord(item)">Update</a></div>
      </div>
    </div>
  </div>
</div>
</div>
