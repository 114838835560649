import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'cookiesblok',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss']
})
export class Cookies {
  public cookieValue: string;



  constructor(private cookieService : CookieService) {
      this.cookieValue = this.cookieService.get('autr0n-acc01');
  }

  acceptCookie(){
    this.cookieService.set('autr0n-acc01','true');
    this.cookieValue = 'true';
    console.log('gezet');
  }
}
