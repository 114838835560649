<div class="container">
  <div class="logo">
    <img src="/assets/autron-hd.png"/>
  </div>
  <div class="header">
    <h1>Register</h1>
  </div>
  <div class="section">
    <form [formGroup]="registerForm">
      <div class="label">
        <label>Email address</label>
      </div>
      <div class="input">
        <input type="email" formControlName="email" class="form-control">
      </div>
      <div class="label">
        <label>Password</label>
      </div>
      <div class="input">
        <input type="password" class="form-control" formControlName="password">
      </div>
      <div class="error">
        <label class="error">{{errorMessage}}</label>
        <label class="success">{{successMessage}}</label>
      </div>
      <div class="button">
        <button type="button" routerLink="/oauth/login">Back to login </button>
        <button type="submit" (click)="tryRegister(registerForm.value)">Register</button>
      </div>
    </form>
  </div>
</div>
